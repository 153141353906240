import React from "react"
import { css } from "styled-components"

export const FormError = ({ children }) => (
  <p
    css={css`
      text-align: center;
      padding: 5px;
      background-color: red;
      margin: 10px 0;
      border-radius: 2px;
      color: #fff;
      height: 40px;
      line-height: 30px;
      font-size: 0.9em;
      letter-spacing: 0.1em;
      font-weight: 600;
    `}
  >
    {children}
  </p>
)
