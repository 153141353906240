import React, { useState } from "react"
import { navigate } from "gatsby"
import { css } from "styled-components"
import axios from "axios"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { FormError } from "../../components/formError"

const wrapperStyles = css`
  padding: 0 15px;
  max-width: 600px;
  margin: 0 auto;

  h1 {
    text-align: center;
    font-size: 1.5em;
    margin: 30px 0;
    font-weight: 600;
    color: rgba(89, 89, 89, 0.7);
  }
`

const formStyles = css`
  margin-top: 20px;
  margin-bottom: 50px;
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-auto-flow: row;
  grid-template-areas:
    "ceremony reception"
    "name name"
    "number number"
    "email email"
    "address address"
    "message message"
    "button button";

  @media (min-width: 480px) {
    grid-template-areas:
      "ceremony reception"
      "name     number"
      "email     email"
      "address     address"
      "message message"
      ".....    button";
  }

  fieldset {
    display: contents;
  }

  input,
  textarea {
    height: 45px;
    padding: 10px;
    border: none;
    background-color: #f4f4f4;
    border-radius: 2px;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  textarea {
    height: unset;
    grid-area: message;
  }

  input[name="_replyto"] {
    grid-area: email;
  }

  input[name="name"] {
    grid-area: name;
  }

  input[name="number"] {
    grid-area: number;
  }

  input[name="address"] {
    grid-area: address;
  }

  input[name="_gotcha"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }
`

const checkboxStyles = css`
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  font-size: 1em;
  height: 30px;
  line-height: 30px;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & span {
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #eee;
  }

  &:hover input ~ span {
    background-color: #ccc;
  }

  & input:checked ~ span {
    background-color: rgba(225, 187, 195, 1);
  }

  span:after {
    content: "";
    position: absolute;
    display: none;
  }

  & input:checked ~ span:after {
    display: block;
  }

  & span:after {
    left: 11px;
    top: 4px;
    width: 7px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`

const buttonStyles = css`
  grid-area: button;
  border: none;
  height: 40px;
  width: 100%;
  color: #fff;
  border-radius: 2px;
  background-color: rgba(225, 187, 195, 1);
  cursor: pointer;
  transition: background-color 200ms;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    background-color: rgba(225, 187, 195, 0.8);
  }
`

const RSVP = () => {
  const [formErrors, setFormErrors] = useState([])
  const [name, setName] = useState("")
  const [number, setNumber] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [attendingCeremony, setAttendingCeremony] = useState(false)
  const [attendingReception, setAttendingReception] = useState(false)

  const encode = data =>
    Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")

  const getFormErrors = () => {
    const errors = []
    if (!name) {
      errors.push(`"Name" is missing.`)
    }
    if (!number) {
      errors.push(`"Number" is missing.`)
    }
    if (!address) {
      errors.push(`"Address" is missing.`)
    }
    if (!email) {
      errors.push(`"Email" is missing.`)
    }
    return errors
  }

  const onSubmit = async function(event) {
    event.preventDefault()
    const formErrors = getFormErrors()

    if (formErrors.length > 0) {
      setFormErrors(formErrors)
      return
    }

    const data = {
      name,
      number,
      address,
      message,
      ceremony: attendingCeremony ? "yes" : "no",
      reception: attendingReception ? "yes" : "no",
      _replyto: email,
      "form-name": "rsvp",
    }

    try {
      await axios.post(event.target.action, encode(data), {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      return navigate("/rsvp/success")
    } catch {
      return navigate("/rsvp/failed")
    }
  }

  return (
    <Layout>
      <SEO title="RSVP" />

      <section css={wrapperStyles}>
        <h1>RSVP</h1>

        {formErrors.length > 0 && (
          <div
            css={css`
              max-width: 300px;
              margin: 30px auto;
            `}
          >
            {formErrors.map(error => (
              <FormError key={error}>{error}</FormError>
            ))}
          </div>
        )}

        <h2>Will you be attending?</h2>

        <form
          css={formStyles}
          data-netlify="true"
          method="POST"
          name="rsvp"
          netlify-honeypot="_gotcha"
          onSubmit={onSubmit}
        >
          <fieldset>
            <input name="_gotcha" />

            <label css={checkboxStyles} style={{ gridArea: "ceremony" }}>
              Ceremony
              <input
                type="checkbox"
                name="ceremony"
                checked={attendingCeremony}
                value={attendingCeremony}
                onChange={() => setAttendingCeremony(!attendingCeremony)}
              />
              <span />
            </label>

            <label css={checkboxStyles} style={{ gridArea: "reception" }}>
              Reception
              <input
                type="checkbox"
                name="reception"
                checked={attendingReception}
                value={attendingReception}
                onChange={() => setAttendingReception(!attendingReception)}
              />
              <span />
            </label>

            <input
              type="text"
              name="name"
              placeholder="Name"
              value={name}
              onChange={({ target: { value } }) => setName(value)}
            />
            <input
              type="text"
              name="number"
              placeholder="Number"
              value={number}
              onChange={({ target: { value } }) => setNumber(value)}
            />
            <input
              type="email"
              name="_replyto"
              placeholder="Email"
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
            />
            <input
              type="text"
              name="address"
              placeholder="Home address"
              value={address}
              onChange={({ target: { value } }) => setAddress(value)}
            />

            <textarea
              placeholder="Attendees, dietary requirements.."
              name="message"
              rows="5"
              value={message}
              onChange={({ target: { value } }) => setMessage(value)}
            ></textarea>

            <button css={buttonStyles} type="submit">
              Submit
            </button>
          </fieldset>
        </form>
      </section>
    </Layout>
  )
}

export default RSVP
